<template>
	<section class="config-valor-admin ml-4 overflow-auto custom-scroll" style="height:calc(100vh - 100px);"
		v-loading="loading">
		<p class="f-20 f-500">Valor administración</p>
		<p class="f-15 mt-4 mb-3 f-400">Asigne el valor de la administración por cada tipo de vivienda</p>
		<p class="d-middle">
			<i class="icon-calendar text-general mr-2" />
			Estos valores empezarán a regir a partir del {{ (typeof tipos_vivienda[0] != 'undefined') ?
					tipos_vivienda[0].vigente_desde : ''
			}}
		</p>
		<div class="mt-4 container">
			<div v-for="(tipo, i) in tipos_vivienda" :key="i">
				<p class="f-15 f-500 mb-2">{{ tipo.nombre }} <i class="f-14 icon-informacion cr-pointer text-general" @click="verInfo(tipo.descripcion)" /> </p>
				<div class="row mb-3">
					<div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-4 mb-2">
						<ValidationProvider tag="div" rules="min_value:0" v-slot="{ errors }"
							name="valor administración">
							<p class="f-12 pl-2 label-inputs">Valor administración</p>
							<el-input placeholder="Valor" v-model="tipo.valores.normal" size="small"></el-input>
							<p class="text-danger f-10">{{ errors[0] }}</p>
						</ValidationProvider>
					</div>
					<div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-4 mb-2">
						<ValidationProvider tag="div" rules="min_value:0" v-slot="{ errors }" name="días de vigencia">
							<p class="f-12 pl-2 label-inputs">Valor por pronto pago</p>
							<el-input placeholder="Valor" v-model="tipo.valores.pronto" size="small"></el-input>
							<p class="text-danger f-10">{{ errors[0] }}</p>
						</ValidationProvider>
					</div>
					<div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-4 mb-2">
						<ValidationProvider tag="div" rules="min_value:0" v-slot="{ errors }" name="valor pago">
							<p class="f-12 pl-2 label-inputs">Valor pago vencido</p>
							<el-input placeholder="Valor" v-model="tipo.valores.vencido" size="small"></el-input>
							<p class="text-danger f-10">{{ errors[0] }}</p>
						</ValidationProvider>
					</div>
				</div>
			</div>
			<!-- btn guardar -->
			<div class="row mt-2 mt-sm-4">
				<div class="col-12">
					<button class="btn btn-general py-1 f-14 f-300 px-3"
						@click="SetFechaImplementacion">Guardar</button>
				</div>
			</div>
		</div>
		<!-- Partials -->
		<modal-set-fecha-implementacion ref="abrirSetFechaImplementacion" @guardar="guardar" />
		<modal ref="modalInfoVivienda" titulo="Información" no-adicional tamano="modal-md">
			<div class="container">
				<div class="row justify-content-center mx-0 py-2">
					{{ info }}
				</div>
			</div>
		</modal>
		<!-- <modal ref="modalInfoVivienda" titulo="Aviso" no-aceptar><p class="text-center">{{ info }}</p></modal> -->
	</section>
</template>
<script>
import moment from 'moment'
import Viviendas from '~/services/viviendas'
export default {
	components: {
		modalSetFechaImplementacion: () => import('./partials/modalSetFechaImplementacion')
	},
	data() {
		return {
			loading: true,
			tipos_vivienda: [],
			info: ''
		}
	},
	created() {
		this.listarValores()
	},
	methods: {
		SetFechaImplementacion() {
			this.$refs.abrirSetFechaImplementacion.toggle()
		},
		async listarValores() {
			try {
				this.loading = true
				const { data } = await Viviendas.listarValores()
				this.tipos_vivienda = data.data
				this.loading = false
			} catch (e) {
				console.log(e)
				this.loading = false
			}
		},
		verInfo(item) {
			this.info = item;
			this.$refs.modalInfoVivienda.toggle()
		},
		async guardar(fecha) {
			try {
				this.loading = true
				let payload = {
					vigencia_desde: moment(fecha).format('YYYY-MM-DD'),
					info: []
				}
				this.tipos_vivienda.forEach(tipo => {
					let temp =
					{
						valor: tipo.valores.normal,
						id_vivienda_tipo: tipo.id_tipo_vivienda,
						tipo: 11
					}
					payload.info.push(temp)

					temp =
					{
						valor: tipo.valores.pronto,
						id_vivienda_tipo: tipo.id_tipo_vivienda,
						tipo: 12
					}
					payload.info.push(temp)

					temp =
					{
						valor: tipo.valores.vencido,
						id_vivienda_tipo: tipo.id_tipo_vivienda,
						tipo: 13
					}
					payload.info.push(temp)

				});

				const { data } = await Viviendas.guardarValores(payload)
				if (data.success) {
					this.listarValores()
				}
				this.loading = false
			} catch (e) {
				console.log(e)
				this.loading = false
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.config-valor-admin {
	i.icon-calendar {
		&::before {
			margin-left: 0px;
		}
	}

}
</style>